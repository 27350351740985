<script setup lang="ts">
interface Props {
  label: string;
  icon?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});
</script>

<template>
  <div class="item" :class="{ 'is-disabled': props.disabled }">
    <Icon v-if="props.icon" :name="props.icon" class="icon" />
    <div class="label">{{ props.label }}</div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.item {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 1.5rem 1rem;
  cursor: pointer;
  color: $color-black;

  &:hover {
    color: $color-primary;
  }

  &.is-disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}
.icon {
  margin-right: 1rem;
  font-size: 2rem;
}
</style>
